import { Fragment, useState,useRef,useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react' 
import { Switch,Popover } from '@headlessui/react'
import { BeakerIcon ,ShoppingCartIcon} from '@heroicons/react/solid'
import Footer from './component/Footer'
import LandingHeader from './component/LandingHeader'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import ReactMapboxGl, { Layer, Feature,Marker } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { url,axios } from './Network'
import {useHistory} from "react-router-dom";
export default function MyModal() {
  let [isOpen, setIsOpen] = useState(true)

  function closeModal() {
    // history.push('/www.dacs')
  }

  function openModal() {
    setIsOpen(true)
  }

  function newURL(){
      history.push('/')
    // window.location.assign('https://store.paculbaflowershop.com')
  }
  const history = useHistory(); 
  return (
    <>
      {/* <div className="fixed inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className="px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          Open dialog
        </button>
      </div> */}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                      <img class="lg:w-50 lg:h-50  rounded-full md:w-20 md:h:20  hidden md:block float-left mr-5 ... " src={'https://scontent.fmnl4-4.fna.fbcdn.net/v/t1.6435-9/106922231_1390116721181933_1716219244335754329_n.png?_nc_cat=100&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=douVUWpI7iUAX8wv4Bp&_nc_ht=scontent.fmnl4-4.fna&oh=6e60fe9e0c07683274d91d11fa5c9890&oe=6160AD67'}/>
                 Your site has been updated
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
              Product Editing will be available on October 1, 2021
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex  mr-2 justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={()=> newURL()}
                  >
                    Continue here
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

