import React,{ Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

import ContentLoader from "react-content-loader"

// const storeID_v2 = process.env.REACT_APP_STORE_ID
// const flux = '60b1c9a9a001ef1e463d52c2' //'Flux-Bouquet'
// const nidz = '5ff00ddaeb2f5d0940dfa186' // Dried

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={340}
    height={84}
    viewBox="0 0 340 84"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="67" height="11" /> 
    <rect x="76" y="0" rx="3" ry="3" width="140" height="11" /> 
    <rect x="127" y="48" rx="3" ry="3" width="53" height="11" /> 
    <rect x="187" y="48" rx="3" ry="3" width="72" height="11" /> 
    <rect x="18" y="48" rx="3" ry="3" width="100" height="11" /> 
    <rect x="0" y="71" rx="3" ry="3" width="37" height="11" /> 
    <rect x="18" y="23" rx="3" ry="3" width="140" height="11" /> 
    <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
  </ContentLoader>
)
export default function Example(props) {
  const [selected, setSelected] = useState([])
 const [address,setAddress] = useState([])
 useEffect(() => {
    setAddress(props.details.storeOptions.deliveryList)
    setSelected(props.details.storeOptions.deliveryList[0])
  }, [])
  function setSelecteDDetails(e){
    setSelected(e)
    props.selected(e)
  }

  const mainContent = () =>{
    return(
<div className="w-72  z-50 top-16">
      <Listbox value={selected} onChange={(e)=>setSelecteDDetails(e)}>
        <div className="relative mt-1  z-50">
          <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-white-500 sm:text-sm">
            <span className="block truncate text-gray-700">{selected.displayName}   {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(selected.price)}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {address.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `${active ? 'text-amber-700 bg-amber-100' : 'text-gray-700'}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                  value={person}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? 'font-medium' : 'hover:text-gray-500 font-normal'
                        } block truncate`}
                      >
                        {person.displayName}    {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(person.price)}
                      </span>
                      {selected ? (
                        <span
                          className={`${
                            active ? 'text-amber-600' : 'text-amber-600'
                          }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
    )
  }
  return  address.lenght ? <MyLoader/> : mainContent()
}
// import React from 'react' 
// import moment from 'moment'
// import { Drawer,Card,Steps,Tabs,Select,Divider} from 'antd';
// import {
//     BrowserView,
//     MobileView,
//     isBrowser,
//     isMobile
//   } from "react-device-detect";  
// const storeColor = process.env.REACT_APP_COLOR
// const { Option } = Select;
// export default function DeliveryPicker({selected,categoryItems,details}) {
//     const [startDate, setStartDate] = React.useState(new Date());
//     const [statusError, setStatus] = React.useState(false);
//     const [addressItem, setAdressItem] = React.useState(null);
//     const [adress, setAddress] = React.useState(details); 
//     const newDate = () =>{
//         var new_date = moment(new Date(), "DD.MM.YYYY"); 
//         console.log(moment().add(1, 'days').calendar())
//         return  new_date.add(1, 'days');
//     }
//     function loadCategoryOption(){

//         var categoryItems = adress.deliveryList 

//         var dropDownList = []
//         categoryItems.map(data => {
//         dropDownList.push(
//             <Option key={data.id} disabled={!data.status} value={data.title}>{data.displayName} - {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(data.price)}</Option>
//             ) 
//         })

//         return dropDownList
//     }

//     function changeItemCategory(value) {
//         var  data =  adress.deliveryList.filter(data =>  data.title === value).[0]
//         selected(data)
//       }
      
//     return (
//         <div>      
//         <Select onChange={(e)=>changeItemCategory(e)} defaultValue={"Select your address"} style={{ width: isBrowser ? '50%' :'100%' ,marginBottom:20 }} >
//         {loadCategoryOption()} 
//         </Select> 
//         {/* <TextareaField
//         label="Other areas not on the list will be charged accordingly depending on the distance"
//         style={{display:'none',marginTop:20}}
//         required
//         validationMessage="Note"
//         />
//         <Divider/>
//          <TextareaField
//         label="Client has an option to book their own delivery service. However, for any damages or losses, Paculba Flower Shop will not responsible"
//         style={{display:'none'}}
//         required
//         validationMessage="Note"
//         /> */}
//         </div>
//     )
// }
