import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react'

const plans = [
  {
    name: 'Startup',
    ram: '12GB',
    cpus: '6 CPUs',
    disk: '160 GB SSD disk',
  },
  {
    name: 'Business',
    ram: '16GB',
    cpus: '8 CPUs',
    disk: '512 GB SSD disk',
  },
  {
    name: 'Enterprise',
    ram: '32GB',
    cpus: '12 CPUs',
    disk: '1024 GB SSD disk',
  },
]

export default function Example(props) {
  const [payments, setPayments] = useState([])

  const [selected, setSelected] = useState(null)
  React.useEffect(() => {
    var initial = props.details.storeOptions.paymentOptions
    var list  =  props.details.storeOptions.paymentOptions.filter(item => item.itemName === "Bank Deposit")[0].items
    var combined =    [...initial, ...list]
    if (props.isItinerary) {
      setPayments(combined.filter(item => item.itemName ===props.selectedPayment)) 
    }else {
      setPayments(combined.filter(item => item.itemName !== "Bank Deposit")) 
    }
    
    setSelected(combined.filter(item => item.itemName !== "Bank Deposit")[0])
  }, [])

  function renderBank(e){
      var items = [<></>]
      console.log(e)
      {e.map((plan) => (
        items.push(
            <RadioGroup.Option
            key={plan.itemName}
            value={plan}
            className={({ active, checked }) =>
              `${
                active
                  ? 'ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60'
                  : ''
              }
              ${
                checked ? 'bg-sky-900 bg-opacity-75 text-white w-full' : 'w-full bg-white'
              }
                relative rounded-lg px-5 py-4 cursor-pointer flex focus:outline-none`
            }
          >

            {({ active, checked }) => (
              <>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className={`font-medium  ${
                          checked ? 'text-white' : 'text-gray-900'
                        }`}
                      >
                        {plan.itemName}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className={`inline ${
                          checked ? 'text-sky-100' : 'text-gray-500'
                        }`}
                      >
                 <img
          className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
          src={plan.icon_url}
          alt=""
        />
                        <span aria-hidden="true">&middot;</span>{' '}
                        <span>{plan.itemDetails}</span>
                      </RadioGroup.Description>
                    </div>
                  </div>
                  {checked && (
                    <div className="flex-shrink-0 text-white">
                      <CheckIcon className="w-6 h-6" />
                    </div>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
          )
      )
      )} 
      return  items
  }

  function getSelectedPayment(e){
      setSelected(e)
    props.selectePayment(e)
  }
  return (
    <div className="w-full">
      <div className="w-full ">
        <RadioGroup value={selected} onChange={(e)=>getSelectedPayment(e)}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="space-y-2">
            {payments.map((plan) => 
            
        <RadioGroup.Option
          key={plan.itemName}
          value={plan}
          className={({ active, checked }) =>
            `${
              active
                ? 'ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60'
                : ''
            }
            ${
              checked ? 'bg-sky-900 bg-opacity-75 text-white border border-gray-500 ease-in-out duration-300 ' : 'bg-white'
            }
              relative rounded-lg hover:shadow-md px-5 cursor-pointer flex focus:outline-none ease-in-out duration-300`
          }
        >
  
              
          {({ active, checked }) => (
            <>
              <div className="flex items-center m-5 justify-between w-full">
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className={`font-medium  ${
                        checked ? 'text-gray-900 ' : 'text-gray-400'
                      }`}
                    >
                      {plan.itemName}
                      
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={`inline ${
                        checked ? 'text-gray-900 ' : 'text-gray-400 text-sm'
                      }`}
                    >
                             <img
        className="inline-block h-8 w-8 rounded-full ring-2 mr-2 ring-white"
        src={plan.icon_url}
        alt=""
      />
                      <span aria-hidden="true" className="mr-2">&middot;</span>{' '}
                      <span>{plan.itemDetails}</span>
                    </RadioGroup.Description>
                  </div>
                </div>

                {checked ? (
                  <div className="flex-shrink-0 text-black">
                   <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
</svg>
                  </div>
                ) : null}
              </div>
              
            </>
          )}
   </RadioGroup.Option>
       
            
            )}
          </div>



        </RadioGroup>
      
      </div>
    </div>
  )
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
